import { PureComponent } from 'react'

import { Autocomplete } from '@eaze/google-places-browser'
import parseGM from '@eaze/parse-google-autocomplete'
import GoogleMapReact from 'google-map-react'
import hoistNonReactStatic from 'hoist-non-react-statics'

import { GMAPS_KEY } from '@helpers/constants'
import errorHandler from 'error-handler'
import promisify from 'helpers/pify'

const { googleMapLoader } = GoogleMapReact

export const bootstrapApiKeys = {
  key: GMAPS_KEY,
  language: 'en',
  libraries: ['places']
}

export default function withMaps(Component) {
  class WithGoogleMaps extends PureComponent {
    state = {
      fetch: null,
      maps: {}
    }

    componentDidMount() {
      if (!process.browser) return

      googleMapLoader(bootstrapApiKeys)
        .then((maps) => {
          const autocomplete = Autocomplete(window.google)
          const fetch = promisify(autocomplete.place)
          this.setState({ fetch, maps })
        })
        .catch((err) => {
          errorHandler(new Error(`Error receiving fetch and google maps: ${err.type}: ${err.message}`))
        })
    }

    fetchPlaces = (value) => {
      if (!value) return Promise.reject(new Error('Attempted to fetch places with no value'))
      if (!this.state.fetch) return Promise.reject(new Error('Attempted to fetch places, but fetch is not defined'))
      return this.state
        .fetch({
          input: value,
          componentRestrictions: {
            country: 'us'
          }
        })
        .then((results) => parseGM(results))
        .catch((err) => errorHandler(err))
    }

    render() {
      return <Component maps={{ fetch: this.fetchPlaces }} {...this.props} />
    }
  }

  return hoistNonReactStatic(WithGoogleMaps, Component)
}
